import {
  defineStore,
  storeToRefs,
} from 'pinia';
import { API_PREFIX } from '~/constants/apiConfiguration';
import { useAuthStore } from '~/stores/auth';
import type { HomePageStateStore } from '~/types/stores/homepage';
import type {
  BannerItem,
  PromoTileItem,
} from '~/types/pages/homepage';

export const useHomePageStore = defineStore({
  id: 'homepage',
  state: (): HomePageStateStore => ({
    bannersData: [],
    promoTilesData: [],
  }),
  getters: {
    getBannerImages: (state) => state.bannersData,
    getPromoTiles: (state) => state.promoTilesData,
  },
  actions: {
    async fetchBannersData(platform: string) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(
          `${API_PREFIX.default}/banners/list`,
          {
            query: {
              lang: getUserData.value.locale,
              platform,
              type: 'BANNER_1',
            },
          },
        );

        this.bannersData = data as BannerItem[];
      } catch (error) {
        throw error;
      }
    },
    async fetchBannersDataForSSR(platform: string) {
      const url = `${useBaseAppUrl().api}${API_PREFIX.default}/banners/list`;
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);
      const startTime = Date.now();
      const { $ssrRequestLogger } = useNuxtApp();

      try {
        const data = await $fetch(url, {
            query: {
              lang: getUserData.value.locale,
              platform,
              type: 'BANNER_1',
            },
          },
        );

        this.bannersData = data as BannerItem[];
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: true,
            result: data,
          });
        }
      } catch (error) {
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: false,
            result: error,
          });
        }
      }
    },
    async fetchPromoTilesData(platform: string) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.promoTiles}/${platform}`,
        { query: { lang: getUserData.value.locale } });

        this.promoTilesData = data as PromoTileItem[];
      } catch (error) {
        throw error;
      }
    },
    async fetchPromoTilesDataForSSR(platform: string) {
      const runtimeConfig = useRuntimeConfig();
      const url = `${runtimeConfig.public.baseApiUrl}${API_PREFIX.promoTiles}/${platform}`;
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const data = await $fetch(url,
        { query: { lang: getUserData.value.locale } });

        this.promoTilesData = data as PromoTileItem[];
      } catch (error) {
        throw error;
      }
    },
  },
});
